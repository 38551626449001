$(document).ready(function () {
    common();
    topSearch();
    fixHeader();
    loadGoogleFont();
    goToTop();
    question();

    //index
    bannerSlider();
    // lazyYoutube();
    initYoutube($('.bg-youtube'));
    initOwl();
    productLinksWithTargetBlank();
    
    //product
    productDetailSlider();
    productSharing();

    //blog
    blogSharing();

});

//頂層選單效果
function topSearch() {
    // top-search-form
    var $topSearchForm = $('.top-search-input');
    var $searchTextBox = $topSearchForm.find('.search-query');
    var isSearchShow = false;
    $('.header-search-btn').click(function () {
        //語言列的才要設active
        if ($topSearchForm.hasClass('search-in-top')) {
            if (isSearchShow == false) {
                $searchTextBox.addClass('active');
                isSearchShow = true;
            } else {
                $searchTextBox.removeClass('active');
                isSearchShow = false;
            }
        }

    });

    //觸發轉頁的搜尋
    var $productOfTopSearchForm = $('#productOfTopSearchForm');
    $productOfTopSearchForm.find('#keywordOfProduct').on('blur', function () {
        $productOfTopSearchForm.submit();
    });
}


function fixHeader() {
    // 上一次的Y(判斷往上或往下);

    var lastScrollTop = 0;
    var $header = $('header');
    var $body = $('body');
    var $main = $('main');
    //讓rwd的選單高度是動態的
    $(window).resize(function () {
        var headerHeight = $header[0].offsetHeight;
        $main.css({
            'padding-top': headerHeight + 'px'
        });
    });
    $(window).scroll(function () {
        var st = $(this).scrollTop();
        console.log('scroll pos:' + st);


        var headerHeight = $header[0].offsetHeight;

        var topPos = +$header.css('top').replace('px', '');
        var marginalVal = lastScrollTop - st;

        topPos = (topPos + marginalVal > 0 || st <= 0) ? 0 : topPos + (marginalVal * 0.5);

        if (st <= headerHeight) {
            // alert(' <= headerHeight');

            $header.removeClass('fixed');
            // 這樣控制會有跳動的不適感，直接留間距就好
            // $('main').css({
            //     'margin-top':'0px'
            // });

            $body.addClass('notop');
        } else {
            if (lastScrollTop > st) {
                //往上
                // console.log('menu 往上');

            } else {

                //往下
                // console.log('menu 往下');

                $header.addClass('fixed');

                $body.removeClass('notop');
            }
        }

        lastScrollTop = st;
    });
    $(window).trigger('resize');
}

function bannerSlider() {
    var bSlider = null;
    // Pace.on('done', function () {
    var $bannerSlider = $('#bannerSlider');
    if ($bannerSlider.length > 0) {

        if ($bannerSlider.find('.ms-view').length == 0) {
            setMasterSliderImageOnScreen($bannerSlider);

            try {
                bSlider = new MasterSlider();
                // adds Arrows navigation control to the slider.

                bSlider.control('timebar', {
                    insertTo: '#bannerSlider'
                });
                bSlider.control('bullets');

                bSlider.control('circletimer', {
                    autohide: false,
                    overVideo: true,
                    color: '#FFFFFF',
                    radius: 4,
                    stroke: 9
                });

                bSlider.control('arrows', {
                    autohide: true
                });
                bSlider.setup('bannerSlider', {
                    // width: 1420, // slider standard width
                    // height: 720, // slider standard height
                    width: 1920, // slider standard width
                    height: 750, // slider standard height
                   
                    minHeight: 400,
                    autoHeight:true,
                    start: 1,
                    space: 0,
                    layout: 'fullwidth',
                    // layout:'fullscreen',
                    loop: true,
                    preload: 0,
                    instantStartLayers: false,
                    autoplay: true,
                    overPause: true,
                    view: "fadeBasic"
                });
                // $(window).trigger('resize');
                $('.master-skeleton-loader').remove();

            } catch (err) {
                console.error(err);
                removeErrorMasterSliderContainer($bannerSlider);
            }
        }
    }
    // });
}

function productDetailSlider() {
    var bSlider = null;
    // Pace.on('done', function () {
    var $productSlider = $('#productSlider');
    if ($productSlider.length > 0) {
        if ($productSlider.find('.ms-view').length == 0) {
            try {

                bSlider = new MasterSlider();

                bSlider.control(
                    'thumblist', {
                        autohide: false,
                        overVideo: true,
                        dir: 'h',
                        speed: 17,
                        inset: false,
                        arrows: false,
                        hover: false,
                        customClass: '',
                        align: 'bottom',
                        type: 'thumbs',
                        margin: 5,
                        width: 100,
                        height: 100,
                        space: 5,
                        fillMode: 'fill'
                    });

                bSlider.setup('productSlider', {
                    width: 500,
                    height: 500,
                    // autoHeight:true,
                    space: 5,
                    view: 'fadeBasic'
                });
                // $(window).trigger('resize');

            } catch (err) {
                console.log(err);
                removeErrorMasterSliderContainer($productSlider);
            }
        }


    }
    // });
}

function goToTop() {
    var $scrollToTop = $('.scrollToTop');
    $(window).scroll(function () {
        if ($scrollToTop.scrollTop() > 300) {
            $scrollToTop.fadeIn();
        } else {
            $scrollToTop.fadeOut();
        }
    });

    //Click event to scroll to top
    var $htmlRoot = $('html, body');
    $scrollToTop.click(function () {
        $htmlRoot.animate({
            scrollTop: 0
        }, 800);
        return false;
    });
}

function question() {
    var $htmlRoot = $('html, body');
    if (window.JUMP_DIR != '') {
        var $q4Elem = $("#" + window.JUMP_DIR);
        if ($q4Elem.length > 0) {
            $htmlRoot.animate({
                scrollTop: $q4Elem.offset().top
            }, 2000);

        }
    }

    var $questions =
        $('.questions');

    var $questionsTitle =
        $questions.find('li > h3');

    $questionsTitle.prepend('<i class="fa fa-caret-right" aria-hidden="true"></i> ');

    $questionsTitle.click(function (e) {

        var $expand = $(this).find('i');
        // console.log($expand);
        if ($expand.hasClass('fa-caret-right')) {
            //open
            var $answer = $(this).next();
            $answer.show();

            $expand.removeClass('fa-caret-right').addClass('fa-caret-down');

            if ($expand.data('isEnableLazyload') != true) {

                var $answerImgs = $answer.find('img');
                if ($answerImgs.length > 0) {
                    //展開就載入圖片
                    startLoadImages($answerImgs);
                    $expand.data('isEnableLazyload', true);
                }
            }
        } else {
            //close
            var $answer =
                $(this).next();
            $answer.hide();

            $expand.removeClass('fa-caret-down')
                .addClass('fa-caret-right');
        }

    });

    var $answers =
        $questions.find('li > div');
    $answers.hide();

}

function blogSharing() {
    initJsSocial($('#blogSharing'));
}

function productSharing() {
    initJsSocial($('#productSharing'));
}

function initJsSocial($targetElem) {
    if ($targetElem.length > 0) {
        $targetElem.jsSocials({
            shares: ["facebook", "twitter"]
        });
    }
}

function lazyYoutube() {
    Pace.on('done', function () {
        initViewLazyOfIframe($('#homeYt'));
    });
}

//第三方iniview載入機制
function initViewLazyOfIframe($targetElem) {
    var isEnable = false;
    if ($targetElem.length > 0) {
        $targetElem.on('inview', function (event, isInView) {

            if (isInView) {
                if (!isEnable) {
                    $targetElem.attr('src', $targetElem.attr('data-url'));
                    isEnable = true;
                }
            }
        });
    }
}

function initOwl() {
    Pace.on('done', function () {

        var $productItemsSlider = $('.product-items');
        owlSlider($productItemsSlider, 4, 1, {
            loop: true,
            nav: true,
            dots: true,
            center: true,
            margin: 20,
            // autoWidth:true,
            autoHeight: false,
            checkVisibility: false,
            // lazyLoad:true,
            responsiveRefreshRate: 0,
            responsive: {
                0: {
                    items: 1.2
                },
                767: {
                    items: 3
                },
                1024: {
                    items: 4,
                }

            }
        });

        var isLzEnableOfProduct = false;
        $productItemsSlider.on('inview', function (event, isInView) {

            if (isLzEnableOfProduct == false) {
                isLzEnableOfProduct = true;
                startLoadImages($productItemsSlider.find('img'));
            }
        });

        var $newsItemsSlider = $('.news-items');
        owlSlider($newsItemsSlider, 4, 1, {
            loop: true,
            nav: true,
            dots: true,
            center: true,
            margin: 20,
            // autoWidth:true,
            autoHeight: false,
            checkVisibility: false,
            // lazyLoad:true,
            responsiveRefreshRate: 0,
            responsive: {
                0: {
                    items: 1.2
                },
                767: {
                    items: 3
                },
                1024: {
                    items: 4,
                }

            }
        });

        var isLzEnableOfNews = false;
        $newsItemsSlider.on('inview', function (event, isInView) {

            if (isLzEnableOfNews == false) {
                isLzEnableOfNews = true;
                startLoadImages($newsItemsSlider.find('img'));
            }
        });


    });
}

function owlSlider($owlSlider, items, slideBy, extendOpts) {
    console.log('owlSlider');
    if ($owlSlider.length > 0) {

        var opts = {
            loop: true,
            items: items,
            animateOut: 'fadeOut',
            animateIn: 'fadeIn',
            margin: 20,
            autoplay: true,
            nav: true,
            dots: true,
            slideBy: slideBy
        }

        $.extend(opts, extendOpts);


        var $owlComp = $owlSlider.owlCarousel(opts);
        var $owlImages = $owlSlider.find('img');
        var isEnableLz = false;
        $owlImages.on('inview', function (event, isInView) {
            if (isInView && isEnableLz == false) {
                isEnableLz = true;
                startLoadImages($owlImages);

            }
        });

        return $owlComp;
    }

}



function loadGoogleFont() {
    Pace.on('done', function () {
        WebFont.load({
            timeout: 2000,
            google: {
                families: [
                    'M PLUS Rounded 1c:500&display=swap'

                ]
            }
        });
    });
}

function initYoutube($bgYoutube) {



    $bgYoutube.on('inview', function (event, isInView) {
        if (isInView) {
            if ($bgYoutube.data('isInit') != true) {
                $bgYoutube.data('isInit', true);

                $bgYoutube.youtube_background();
            }

        }
    });

}

function productLinksWithTargetBlank() {
    $('.product-box').find('a').click(function (e) {
        e.preventDefault();
        e.stopPropagation();

        var url = $(this).attr('href');
        window.open(url, '_blank');
    });
}